@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Nunito Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.notification-shadow {
  box-shadow: 0px 1px 6px #ED172780;
}

.input-shadow {
  box-shadow: 0px 8px 24px #00000014;
}

.card-shadow {
  box-shadow: 0px 8px 16px #16171908;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  margin-top: 10px;
  z-index: 99998;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
  border: 3px solid white;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}
